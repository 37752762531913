import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import CSVLoader from "./CSVLoader";
import Sidebar from "./Sidebar";
import Graph from "./Graph";

function App() {
  return (
    <div className="App">
      {/* <Graph file="/data/data-m1supply.csv" /> */}
      <BrowserRouter>
        <div className="main-wrap">
          <Sidebar />
          <CSVLoader />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
