import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Graph from './Graph';  // Your graph component

const CSVLoader = () => (
  <div className='main-content'>
    <Routes>
      <Route path='/' element={<Graph graphType='gdp-growth' />} />
      <Route path='/gdp' element={<Graph graphType='gdp' />} />
      <Route path='/unemployment' element={<Graph graphType='unemployment' />} />
      <Route path='/payrolls' element={<Graph graphType='payrolls' />} />
      <Route path='/indpro' element={<Graph graphType='indpro' />} />
      <Route path='/consumer-spending' element={<Graph graphType='personal-consumption' />} />
      <Route path='/inflation' element={<Graph graphType='inflation' />} />
      <Route path='/home-sales' element={<Graph graphType='home-sales' />} />
      <Route path='/housing-starts' element={<Graph graphType='housing-starts' />} />
      <Route path='/construction-spending' element={<Graph graphType='construction-spending' />} />
      <Route path='/vehicle-sales' element={<Graph graphType='vehicle-sales' />} />
    </Routes>
  </div>
);

export default CSVLoader;
