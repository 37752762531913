import moment from 'moment-timezone';
import Papa from 'papaparse';
import vars from './Vars.js'

const GetDateFromStr = (str) => {
    let date = moment.tz(str, "UTC");
    return date;
}

const FetchFile = (file, numLines) => {
    console.log('Fetching file: ' + file);
    return fetch(file)
        .then((response) => response.text())
        .then((data) => {
            const parsedData = [];
            Papa.parse(data, {
                header: false,
                skipEmptyLines: true,
                step: function (result) {
                    let numberValue = Number(result.data[1]);
                    
                    // If it's a decimal number, round it to 2 decimal places
                    if (numberValue % 1 !== 0) {
                        numberValue = parseFloat(numberValue.toFixed(2));
                    }

                    console.log('Date: ' + result.data[0]);

                    let formattedDate = moment(result.data[0]).format('YYYY-MM');
                    parsedData.push({
                        date: formattedDate,
                        number: numberValue,
                    });
                },
            });
            if (numLines && numLines > 0) {
                let s = parsedData.slice(-1 * numLines);
                return parsedData.slice(-1 * numLines);
            }
            return parsedData;
        })
        .catch(error => {
            console.error('An error occurred:', error);
        });
}

// Returns data with Month-over-Month growth
const MomGrowth = (data) => {
    let dateMap = new Map();
    let momData = [];

    for (let i = 0; i < data.length; i++) {
        dateMap.set(data[i]['date'], data[i]['number']);
    }

    for (let i = 0; i < data.length; i++) {
        let dataPoint = data[i];
        let date = GetDateFromStr(dataPoint['date']);
        let value = dataPoint['number'];

        let oneMonthAgoDate = date.clone().subtract(1, 'months');
        let oneMonthAgo = oneMonthAgoDate.format('YYYY-MM');

        if (dateMap.has(oneMonthAgo)) {
            let oneMonthAgoValue = dateMap.get(oneMonthAgo);
            let growth = value - oneMonthAgoValue;
            momData.push({
                date: dataPoint['date'],
                number: growth,
            });
        }
    }

    return momData;
}

// Annual quarter over quarter growth
const AqoqGrowth = (data) => {
    let dateMap = new Map();
    let growthData = [];

    for (let i = 0; i < data.length; i++) {
        dateMap.set(data[i]['date'], data[i]['number']);
    }

    for (let i = 0; i < data.length; i++) {
        let dataPoint = data[i];

        let date = GetDateFromStr(dataPoint['date']);
        let value = dataPoint['number'];

        let previousYearQuarterDate = date.clone().subtract(1, 'year');
        let previousQuarter = previousYearQuarterDate.format('YYYY-MM');
        
        if (dateMap.has(previousQuarter)) {
            let previousYearValue = dateMap.get(previousQuarter);
            let growth = (value - previousYearValue) / previousYearValue;

            // multiply by 10 and round to 1 decimal place. This will give the value in percentage
            growth = Math.round(growth * 1000) / 10; 

            growthData.push({
                date: dataPoint['date'],
                number: growth
            });
        } else {
            console.log('map does not have the date: ' + previousQuarter)
        }
    }

    return growthData;
}

const GetDefaultTimeFrame = (graphType) => {
    switch (graphType) {
        case 'gdp-growth':
            return '3yr';
        case 'gdp':
            return '3yr';
        case 'unemployment':
            return '1yr';
        case 'payrolls':
            return '1yr';
        case 'indpro':
            return '1yr';
        case 'personal-consumption':
            return '1yr';
        case 'inflation':
            return '3yr';
        case 'home-sales':
            return '1yr';
        case 'housing-starts':
            return '1yr';
        case 'construction-spending':
            return '1yr';
        case 'vehicle-sales':
            return '1yr';
        default:
            return '5yr';
    }
}

const GetNumLinesFromTimeFrame = (timeFrame, graphType) => {
    if (!timeFrame) {
        timeFrame = GetDefaultTimeFrame(graphType);
    }

    switch (timeFrame) {
        case '1yr':
            return 12;
        case '3yr':
            return 12;
        case '5yr':
            if (graphType == 'gdp-growth' || graphType == 'gdp')
                return 20;
            return 60;
        case '10yr':
            if (graphType == 'gdp-growth' || graphType == 'gdp')
                return 40;
            return 120;
        case 'max':
            return -1;
        default:
            return -1;
    }
}

// Divide all the elements in the array by a given number
const DivideArrayBy = (data, divisor) => {
    for (let i = 0; i < data.length; i++) {
        data[i]['number'] = data[i]['number'] / divisor;
    }
    return data;
}

// This assumes that the data array has only 2 values
const GetChangeInValues = (data) => {
    let firstValue = data[0]['number'];
    let lastValue = data[data.length - 1]['number'];
    let change = lastValue - firstValue;

    let date = moment(data[data.length - 1]['date']).format('YYYY-MM');
    let initialDate = moment(data[0]['date']).format('YYYY-MM');

    return {
        date: date,
        initialDate: initialDate,
        change: change,
    };
}

const GetTotalConstructionSpending = (timeFrame) =>  {
    let file = vars.ConstructionSpendingFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'construction-spending');

    return FetchFile(file, numLines).then((data) => {
        return DivideArrayBy(data, 1000000);
    });
}

const GetTotalConstructionSpendingLabelData = () => {
    let file = vars.ConstructionSpendingFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        v.change = v.change / 1000000;
        v.change = Math.round(v.change * 100) / 100;
        let value = data[data.length - 1]['number']/1000000;
        value = Math.round(value * 100) / 100;
        let valueMsg = '$' + value + ' trillion';

        let msg = '';
        if (v.change > 0) {
            msg = 'Up by $' + v.change + ' billion from ' + v.initialDate;
        } else {
            msg = 'Down by $' + Math.abs(v.change) + ' billion from ' + v.initialDate;
        }

        return {
            msg: msg,
            value: valueMsg,
        }
    });
}
            
const GetGDPData = (timeFrame) =>  {
    let file = vars.GDPFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'gdp');

    return FetchFile(file, numLines).then((data) => {
        // if (timeFrame && timeFrame == '3yr')
            // data = data.slice(0, 12);
        return DivideArrayBy(data, 1000);
    });
}

const GetGDPLabelData = () => {
    let file = vars.GDPFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        v.change = Math.round(v.change * 10) / 10;
        let value = Math.round(data[data.length - 1]['number'] * 10) / 10;
        let valueMsg = '$' + value/1000 + ' trillion';
        
        let msg = '';
        if (v.change > 0) 
            msg = 'Up by $' + v.change + ' billion since ' + v.initialDate;
         else 
            msg = 'Shrunk by $' + Math.abs(v.change) + ' dollars since ' + v.initialDate;
        
        return {
            value : valueMsg,
            msg: msg,
        };
    });
}


const GetGDPGrowthData = (timeFrame) =>  {
    let file = vars.GDPGrowthFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'gdp-growth');

    return FetchFile(file, numLines).then((data) => {
        return AqoqGrowth(data);
    });
}

const GetGDPGrowthLabelData = () => {
    let file = vars.GDPGrowthFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        let percentChange = v.change/data[0]['number'] * 100;
        percentChange = Math.round(percentChange * 10) / 10;
        // v.change = Math.round(v.change * 10) / 10;
        let msg = '';
        if (percentChange > 0)
            msg = 'Grown by ' + percentChange + '% since ' + v.initialDate;
        else
            msg = 'Shrunk by ' + Math.abs(percentChange) + '% since ' + v.initialDate;
        
        return {
            value : percentChange + '%',
            msg: msg,
        };
    });
}

const GetUnemploymentRateData = (timeFrame) =>  {
    let file = vars.UnemploymentRateFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'unemployment');
    return FetchFile(file, numLines);
}

const GetUnemploymentRateLabelData = () => {
    let file = vars.UnemploymentRateFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        v.change = Math.round(v.change * 10) / 10;

        let msg = '';
        if (v.change > 0)
            msg = 'Up from ' + data[0]['number'] + '% on ' + v.initialDate;
        else
            msg = 'Down from ' + data[0]['number'] + '% on ' + v.initialDate;

        return {
            value : data[1]['number'] + '%',
            msg: msg,
        };
    });
}

const GetHomeSalesData = (timeFrame) =>  {
    let file = vars.HomeSalesFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'home-sales');
    
    return FetchFile(file, numLines).then((data) => {
        return DivideArrayBy(data, 1000000);
    });
}

const GetHomeSalesLabelData = () => {
    let file = vars.HomeSalesFileName
    return FetchFile(file, 2).then(data => {
        let v = GetChangeInValues(data);
        v.change = v.change/1000;
        v.change = Math.round(v.change * 10) / 10;
        let value = data[data.length - 1]['number']/1000000;
        let valueMsg = Math.round(value *100)/100 + ' million units';
        
        let msg = '';
        if (v.change > 0) 
            msg = 'Up from' + v.change + ' thousand units on ' + v.initialDate;
         else 
            msg = 'Shrunk by ' + Math.abs(v.change) + ' thousand units on ' + v.initialDate;
        
        return {
            value : valueMsg,
            msg: msg,
        };
        
    });
}

const GetHousingStartsData = (timeFrame) =>  {
    let file = vars.HousingStartsFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'housing-starts');
    
    return FetchFile(file, numLines).then((data) => {
        return DivideArrayBy(data, 1000);
    });
}

const GetHousingStartsLabelData = () => {
    let file = vars.HousingStartsFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        v.change = Math.round(v.change * 10) / 10;
        let value = data[data.length - 1]['number']/1000;
        let valueMsg = Math.round(value *100)/100 + ' million units';
        
        let msg = '';
        if (v.change > 0) 
            msg = 'Up from' + v.change + ' thousand units on ' + v.initialDate;
         else 
            msg = 'Shrunk by ' + Math.abs(v.change) + ' thousand units on ' + v.initialDate;
        
        return {
            value : valueMsg,
            msg: msg,
        };
        
    });
}


const GetIndproData = (timeFrame) =>  {
    let file = vars.IndProFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'indpro');
    
    return FetchFile(file, numLines).then((data) => {
        return data;
    });
}

const GetIndproLabelData = () => {
    let file = vars.IndProFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        v.change = Math.round(v.change * 100) / 100;
        let value = Math.round(data[data.length - 1]['number'] * 100) / 100;
        let valueMsg = value + '% of 2017 average';
        
        let msg = '';
        if (v.change > 0) 
            msg = 'Up by ' + v.change + '% ' + v.initialDate;
         else 
            msg = 'Shrunk by $' + Math.abs(v.change) + '% since ' + v.initialDate;
        
        return {
            value : valueMsg,
            msg: msg,
        };
    });
}

const GetInflationData = (timeFrame) =>  {
    let file = vars.InflationDataFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'inflation');
    if (!timeFrame || (timeFrame && timeFrame == '3yr')) {
        numLines = 36;
    }

    return FetchFile(file, numLines).then((data) => {
        return AqoqGrowth(data);
    });
}

const GetInflationLabelData = () => {
    let file = vars.InflationDataFileName;
    return FetchFile(file, 14).then((data) => {
        let currentCpiValue = data[data.length - 1]['number'];
        let oneMonthAgoCpiValue = data[data.length - 2]['number'];
        let cpiValueOneYearAgo = data[1]['number'];
        let cpiValue13MonthsAg0 = data[0]['number'];

        let currentInflation = (currentCpiValue - cpiValueOneYearAgo)/cpiValueOneYearAgo * 100;
        currentInflation = Math.round(currentInflation * 10) / 10;

        let oneMonthAgoInflation = (oneMonthAgoCpiValue - cpiValue13MonthsAg0)/cpiValue13MonthsAg0 * 100;
        oneMonthAgoInflation = Math.round(oneMonthAgoInflation * 10) / 10;

        let oneMonthAgoDate = GetDateFromStr(data[data.length - 2]['date']);
        let formattedDate = oneMonthAgoDate.format('YYYY-MM');

        let valueMsg = currentInflation + '%';
        let msg = '';
        if (currentInflation > oneMonthAgoInflation)
            msg = 'Up from ' + oneMonthAgoInflation + '% since ' + formattedDate;
        else
            msg = 'Down from ' + oneMonthAgoInflation + '% since ' + formattedDate;

        return {
            value : valueMsg,
            msg: msg,
        };
    });
}

const GetPayrollsData = (timeFrame) =>  {
    let file = vars.PayrollsFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'payrolls');
    
    return FetchFile(file, numLines).then((data) => {
        return data;
    });
}

const GetPayrollsLabelData = () => {
    let file = vars.PayrollsFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        let value = data[data.length - 1]['number']/1000;
        let valueMsg = Math.round(value *100)/100 + ' million jobs';
        
        let msg = '';
        if (v.change > 0) 
            msg = 'Up from ' + data[0]['number']/1000 + ' million payrolls on ' + v.initialDate;
         else 
            msg = 'Shrunk from ' + data[0]['number']/1000 + ' million payrolls on ' + v.initialDate;
        
        return {
            value : valueMsg,
            msg: msg,
        };
        
    });
}

const GetPersonalConsumptionData = (timeFrame) =>  {
    let file = vars.PersonalConsumptionFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'personal-consumption');
    
    return FetchFile(file, numLines).then((data) => {
        return DivideArrayBy(data, 1000);
    });
}

const GetPersonalConsumptionLabelData = () => {
    let file = vars.PersonalConsumptionFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        v.change = Math.round(v.change * 10) / 10;
        let value = data[data.length - 1]['number']/1000;
        let valueMsg = Math.round(value *100)/100 + ' trillion dollars';

        let initialNumber = data[0]['number']/1000;
        initialNumber = Math.round(initialNumber * 100) / 100;

        let msg = '';
        if (v.change > 0)
            msg = 'Up from ' + initialNumber + ' trillion dollars on ' + v.initialDate;
        else
            msg = 'Shrunk from ' + initialNumber + ' trillion dollars on ' + v.initialDate;

        return {
            value : valueMsg,
            msg: msg,
        };
    });
}

const GetTotalVehicleSalesData = (timeFrame) =>  {
    let file = vars.VehicleSalesFileName;
    let numLines = GetNumLinesFromTimeFrame(timeFrame, 'vehicle-sales');
    
    return FetchFile(file, numLines).then((data) => {
        return data;
    });
}

const GetTotalVehicleSalesLabelData = () => {
    let file = vars.VehicleSalesFileName;
    return FetchFile(file, 2).then((data) => {
        let v = GetChangeInValues(data);
        v.change = Math.round(v.change * 100) / 100;
        let value = data[data.length - 1]['number'];
        let valueMsg = Math.round(value *100)/100 + ' million vehicles';

        let initialNumber = data[0]['number']/1000;
        initialNumber = Math.round(initialNumber * 100) / 100;

        let msg = '';
        if (v.change > 0)
            msg = 'Up by ' + v.change + ' million vehicles since ' + v.initialDate;
        else
            msg = 'Shrunk by ' + v.change + ' million vehicles since ' + v.initialDate;

        return {
            value : valueMsg,
            msg: msg,
        };
    });
}

export default {
    GetTotalConstructionSpending,
    GetGDPData,
    GetGDPGrowthData,
    GetUnemploymentRateData,
    GetHomeSalesData,
    GetHousingStartsData,
    GetIndproData,
    GetInflationData,
    GetPayrollsData,
    GetPersonalConsumptionData,
    GetTotalVehicleSalesData,

    GetGDPLabelData,
    GetGDPGrowthLabelData,
    GetUnemploymentRateLabelData,
    GetHomeSalesLabelData,
    GetHousingStartsLabelData,
    GetIndproLabelData,
    GetInflationLabelData, //number is wrong
    GetPayrollsLabelData,
    GetPersonalConsumptionLabelData,
    GetTotalVehicleSalesLabelData,
    GetTotalConstructionSpendingLabelData,

    FetchFile, 
    GetDateFromStr, 
    MomGrowth, 
    AqoqGrowth
};

/*
Sample website: https://tradingeconomics.com/united-states/gdp-growth-annual

    Key economic indicators are:
        1. GDP https://fred.stlouisfed.org/series/GDP
        2. Employment figures https://fred.stlouisfed.org/series/PAYEMS
        3. Indusrtrial production https://fred.stlouisfed.org/series/INDPRO
        4. Consumer spending https://fred.stlouisfed.org/series/PCEC96
        5. Inflation - https://fred.stlouisfed.org/series/CPIAUCSL
        6. Home sales https://fred.stlouisfed.org/series/EXHOSLUSM495S, https://fred.stlouisfed.org/series/MSPUS
        7. Home building https://fred.stlouisfed.org/series/HOUST
        8. Construction spending https://fred.stlouisfed.org/series/TTLCONS
        9. Manufacturing Demand https://fred.stlouisfed.org/series/AMTMNO
        10. Retail sales 
            total vehicle sales - https://fred.stlouisfed.org/series/TOTALSA
        11. Unemployment rate https://fred.stlouisfed.org/series/UNRATE

*/


/*New data

30yr avg mortgage rate: 
curl 'https://fred.stlouisfed.org/graph/fredgraph.csv?id=MORTGAGE30US&revision_date=2023-08-18&nd=1971-04-02'

Total us debt
curl 'https://fred.stlouisfed.org/graph/fredgraph.csv?id=GFDEBTN&revision_date=2023-08-18&nd=1966-01-01'


India gdp
curl 'https://fred.stlouisfed.org/graph/fredgraph.csv?id=MKTGDPINA646NWDB&revision_date=1960-01-01&nd=2022-01-01'


Germany gdp
curl 'https://fred.stlouisfed.org/graph/fredgraph.csv?id=MKTGDPDEA646NWDB&revision_date=1970-01-01&nd=2022-01-01'

Britain gdp
curl 'https://fred.stlouisfed.org/graph/fredgraph.csv?id=NGDPSAXDCGBQ&revision_date=1955-01-01&nd=2023-01-01'

*/