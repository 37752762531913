import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Scatter,
  Area,
  AreaChart,
  Label,
} from "recharts";
import indicators from "./Indicators";

const Graph = ({ graphType }) => {
  const [data, setData] = useState([]);
  const [graphTitle, setGraphTitle] = useState("");
  const [yAxisLabel, setYAxisLabel] = useState("");
  const [headlineValue, setHeadlineValue] = useState("");
  const [headlineMessage, setHeadlineMessage] = useState("");
  const [show1yButton, setShow1yButton] = useState(false);
  const [show3yButton, setShow3yButton] = useState(true);
  const [show5yButton, setShow5yButton] = useState(true);
  const [show10yButton, setShow10yButton] = useState(true);
  const [showMaxButton, setShowMaxButton] = useState(true);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0); // Default active button index

  const loadData = (timeFrame) => {
    switch (graphType) {
      case "gdp-growth":
        setGraphTitle("GDP Growth");
        indicators.GetGDPGrowthData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetGDPGrowthLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "gdp":
        setGraphTitle("Total GDP");
        indicators.GetGDPData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetGDPLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "unemployment":
        setGraphTitle("Unemployment Rate");
        indicators.GetUnemploymentRateData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetUnemploymentRateLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "payrolls":
        setGraphTitle("Total Non-Farm Payrolls");
        indicators.GetPayrollsData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetPayrollsLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "indpro":
        setGraphTitle("Industrial Production");
        indicators.GetIndproData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetIndproLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "personal-consumption":
        setGraphTitle("Consumer Spending");
        indicators.GetPersonalConsumptionData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetPersonalConsumptionLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "inflation":
        setGraphTitle("Inflation");
        indicators.GetInflationData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetInflationLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "home-sales":
        setGraphTitle("Existing Home Sales");
        indicators.GetHomeSalesData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetHomeSalesLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;

      case "housing-starts":
        setGraphTitle("New Housing Starts");
        indicators.GetHousingStartsData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetHousingStartsLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "construction-spending":
        setGraphTitle("Total Construction Spending");
        indicators.GetTotalConstructionSpending(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetTotalConstructionSpendingLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
        break;
      case "vehicle-sales":
        setGraphTitle("Vehicle Sales");
        indicators.GetTotalVehicleSalesData(timeFrame).then((data) => {
          setData(data);
        });
        indicators.GetTotalVehicleSalesLabelData().then((data) => {
          setHeadlineValue(data.value);
          setHeadlineMessage(data.msg);
        });
      default:
        break;
    }
  };

  const load1yrData = () => {
    loadData("1yr");
  };

  const load3yrData = () => {
    loadData("3yr");
  };

  const load5yrData = () => {
    loadData("5yr");
  };

  const load10yrData = () => {
    loadData("10yr");
  };

  const loadMaxData = () => {
    loadData("max");
  };

  // Load the data when the component mounts
  useEffect(() => {
    if (
      graphType == "gdp" ||
      graphType == "gdp-growth" ||
      graphType == "inflation"
    ) {
      setShow1yButton(false);
      setShow3yButton(true);
    } else {
      setShow1yButton(true);
      setShow3yButton(false);
    }

    switch (graphType) {
      case "gdp-growth":
        setYAxisLabel("Growth (%)");
        break;
      case "gdp":
        setYAxisLabel("$ (trillions)");
        break;
      case "unemployment":
        setYAxisLabel("Unemployment (%)");
        break;
      case "indpro":
        setYAxisLabel("Index (2017=100)");
        break;
      case "payrolls":
        setYAxisLabel("thousands of jobs");
        break;
      case "inflation":
        setYAxisLabel("Inflation (%)");
        break;
      case "home-sales":
        setYAxisLabel("millions of units");
        break;
      case "personal-consumption":
        setYAxisLabel("$ (trillions)");
        break;
      case "construction-spending":
        setYAxisLabel("$ (trillions)");
        break;
      case "vehicle-sales":
        setYAxisLabel("millions of units");
        break;
    }

    loadData();
  }, [graphType]);

  const CustomScatterDot = (props) => {
    const { cx, cy, fill } = props;
    return <circle cx={cx} cy={cy} r={5} fill={fill} />;
  };

  const TickValues = () => {
    // We want to show 12 points on the graph
    // This will divide the data array into 12 equal parts
    let interval = 1;
    if (data.length > 12) interval = Math.floor(data.length / 12);

    let xPoints = [];
    let yPoints = [];
    for (let i = 0; i < data.length; i += interval) {
      xPoints.push(data[i].date);
      yPoints.push(data[i].value);
    }

    return { xPoints, yPoints };
  };

  const CustomTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
      const date = payload[0].payload.date;
      const value = parseFloat(payload[0].value.toFixed(2)); // Round the value to 2 decimal places
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`Date: ${date}, Value: ${value}`}</p>
        </div>
      );
    }

    return null;
  };

  const xTickValues = TickValues().xPoints;
  const yTickValues = TickValues().yPoints;
  const CustomDot = (props) => {
    const { cx, cy, payload } = props;

    // Check if the dot corresponds to a tick value
    if (
      xTickValues.includes(payload.date) &&
      yTickValues.includes(payload.value)
    ) {
      return <circle cx={cx} cy={cy} r={0} fill="red" />;
    }

    return null; // Return null to render nothing for non-tick values
  };

  const CustomizedXAxisTick = (props) => {
    const { index, x, y, payload } = props;

    // Return null for the first tick to hide it
    // This hides the value on the bottom-left of the graph
    if (index === 0) return null;

    return (
      <g transform={`translate(${x},${y - 10})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          fontSize={16}
        >
          {payload.value}
        </text>
      </g>
    );
  };
  const handleButtonClick = (index) => {
    setActiveButtonIndex(index);
    // Add your data loading logic here based on the index
  };

  const buttons = [
    // { label: "1y", onClick: load1yrData },
    { label: "3y", onClick: load3yrData },
    { label: "5y", onClick: load5yrData },
    { label: "10y", onClick: load10yrData },
    { label: "Max", onClick: loadMaxData },
  ];
  return (
    <div className="MainContentInfo">
      <h1 className="main-title">
        {graphTitle} : <span>{headlineValue}</span>
      </h1>
      <div className="chartbtnWrap">
        <p className="headlineMessage">{headlineMessage}</p>
        <div className="btn-wrap">
          {/* {show1yButton && (
            <>
              <button className="btn-blue" onClick={load1yrData}>
                1y
              </button>
            </>
          )}
          {show3yButton && (
            <>
              <button className="btn-blue" onClick={load3yrData}>
                3y
              </button>
            </>
          )}
          {show5yButton && (
            <>
              <button className="btn-blue" onClick={load5yrData}>
                5y
              </button>
            </>
          )}
          {show10yButton && (
            <>
              <button className="btn-blue" onClick={load10yrData}>
                10y
              </button>
            </>
          )}
          {showMaxButton && (
            <>
              <button className="btn-blue" onClick={loadMaxData}>
                Max
              </button>
            </>
          )} */}
          {buttons.map((button, index) => (
            <button
              key={index}
              className={`btn-blue ${
                activeButtonIndex === index ? "active" : ""
              }`}
              onClick={() => {
                handleButtonClick(index);
                button.onClick(); // Call the corresponding data loading function
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={500}>
      <AreaChart width={1000} height={500} data={data}>
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1268B2" stopOpacity={1} />
            <stop offset="95%" stopColor="#D9D9D9" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid
          strokeDasharray="1"
          horizontal="true"
          vertical=""
          stroke="#243240"
        />
        <XAxis
          dataKey="date"
          interval={"preserveStartEnd"}
          angle={-45}
          tick={CustomizedXAxisTick}
          ticks={xTickValues}
          height={80}
          label={{
            position: "insideBottom",
            offset: 0,
          }}
        >
          <Label
            value="Date"
            offset={0}
            position="insideBottom"
            style={{ fontSize: "18px", fontWeight:'500' }}
          />
        </XAxis>
        <YAxis
          label={{
            value: yAxisLabel,
            angle: -90,
            position: "insideLeft",
            offset: -15,
            style: { fontSize: "16px" },
          }}
          axisLine={false}
          tickLine={false}
          // scale={'quantile'}
          // cursor={true}
        >
          <Label
            value="Date"
            angle={-90}
            position="insideLeft"
            style={{ fontSize: "18px", fontWeight:'500' }}
          />
        </YAxis>
        <Tooltip content={CustomTooltip} cursor={true} />
        <Scatter dataKey="value" fill="#8884d8" shape={<CustomScatterDot />} />
        <Area
          type="monotone"
          dataKey="number"
          stroke="#1268B2"
          fill="url(#colorGradient)"
          dot={<CustomDot xTickValues={xTickValues} />}
          activeDot={{
            fill: "#fff",
            stroke: "#1268B2",
            strokeWidth: 5,
            r: 8,
          }}
          name="test value"
        />
      </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graph;
