import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Link, NavLink } from "react-router-dom";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    document.body.classList.toggle("sidebar-open");
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
    document.body.classList.remove("sidebar-open");
  };

  useEffect(() => {
    const handleNavLinkClick = () => {
      if (sidebarOpen) {
        setSidebarOpen(false);
        document.body.classList.remove("sidebar-open");
      }
    };

    const navLinks = document.querySelectorAll(".sidebarmenu a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavLinkClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavLinkClick);
      });
    };
  }, [sidebarOpen, setSidebarOpen]); // Add setSidebarOpen as a dependency

  return (
    <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
      <Link className="sidebarMenu" to="#" onClick={toggleSidebar}>
        {sidebarOpen ? <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/><path fill="currentColor" d="M20 17.5a1.5 1.5 0 0 1 .144 2.993L20 20.5H4a1.5 1.5 0 0 1-.144-2.993L4 17.5h16Zm0-7a1.5 1.5 0 0 1 0 3H4a1.5 1.5 0 0 1 0-3h16Zm0-7a1.5 1.5 0 0 1 0 3H4a1.5 1.5 0 1 1 0-3h16Z"/></g></svg>}
      </Link>
      <Link className="Nav-logo" onClick={closeSidebar} to="/">
        <h1>Macro Snapshot</h1>
      </Link>
      <ul className="sidebarmenu">
        <li>
          <NavLink to="/" activeClassName="active-link">GDP Growth</NavLink>
        </li>
        <li>
          <NavLink to="/gdp" activeClassName="active-link">GDP</NavLink>
        </li>
        <li>
          <NavLink to="/unemployment" activeClassName="active-link">Unemployment Rate</NavLink>
        </li>
        <li>
          <NavLink to="/payrolls" activeClassName="active-link">Payrolls</NavLink>
        </li>
        <li>
          <NavLink to="/indpro" activeClassName="active-link">Industrial Production</NavLink>
        </li>
        <li>
          <NavLink to="/consumer-spending" activeClassName="active-link">Consumer Spending</NavLink>
        </li>
        <li>
          <NavLink to="/inflation" activeClassName="active-link">Inflation</NavLink>
        </li>
        <li>
          <NavLink to="/home-sales" activeClassName="active-link">Existing Home Sales</NavLink>
        </li>
        <li>
          <NavLink to="/housing-starts" activeClassName="active-link">Housing Starts</NavLink>
        </li>
        <li>
          <NavLink to="/construction-spending" activeClassName="active-link">Construction Spending</NavLink>
        </li>
        <li>
          <NavLink to="/vehicle-sales" activeClassName="active-link">Total Vehicle Sales</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
