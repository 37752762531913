const ConstructionSpendingFileName = "/data/ttlcons.csv";
const GDPFileName = "/data/gdp.csv";
const GDPGrowthFileName = "/data/gdp.csv";
const UnemploymentRateFileName = "/data/unrate.csv";
const HomeSalesFileName = "/data/exhoslusm495s.csv";
const IndProFileName = "/data/indpro.csv";
const InflationDataFileName = "/data/cpiaucsl.csv";
const PayrollsFileName = "/data/payems.csv";
const PersonalConsumptionFileName = "/data/pcec96.csv";
const VehicleSalesFileName = "/data/totalsa.csv";
const HousingStartsFileName = "/data/houst.csv";

export default {
    ConstructionSpendingFileName,
    GDPFileName,
    GDPGrowthFileName,
    UnemploymentRateFileName,
    HomeSalesFileName,
    IndProFileName,
    InflationDataFileName,
    PayrollsFileName,
    PersonalConsumptionFileName,
    VehicleSalesFileName,
    HousingStartsFileName
}